import { IconContext } from 'react-icons'
import { FaPython, FaImage, FaChessBoard } from "react-icons/fa";

import AppContent from "../AppContent";

const Cat = () => {
    const title = "Cat Detector with a Haar Cascade Classifier";
    const src = "../images/cat.svg";
    const language = (
        <>
        <ul className="no-point">
            <li>
                <IconContext.Provider value={{ className: "fa-icon" }}>
                    <FaPython />
                </IconContext.Provider>Python
            </li>
        </ul>
        <h2>Keyword</h2>
        <ul className="no-point">
            <li>
                <IconContext.Provider value={{ className: "fa-icon" }}>
                    <FaImage />
                </IconContext.Provider>Image Recognition
            </li>
            <li>
                <IconContext.Provider value={{ className: "fa-icon" }}>
                    <FaChessBoard />
                </IconContext.Provider>Haar Cascade Classifier
            </li>
        </ul>
        </>
    );
    const description = (
        <>
            <p><span className='bold'>Haar Cascade Classifiers</span> are trained using Haar-like features, which focuses on the difference of pixel values.</p>
            <p>Thanks to its nature, increasing the image contrast helps accurate classifications. In addition, tuning the scale factor (i.e., how much the image size is reduced at each image scale) is important.</p>
            <p>Furthermore, experiments show that applying semantic segmentation to the image increases the successful detection rate. This is because semantic segmentation is the binarization of images, which increases the contrast.</p>
        </>
    );

    const images = [
        "../images/cat1.svg",
        "../images/cat2.svg",
        "../images/cat3.svg",
        "../images/cat4.svg",
        "../images/cat5.svg",
        "../images/cat6.svg",
        "../images/cat7.svg",
        "../images/cat8.svg",
        "../images/cat9.svg",
        "../images/cat10.svg",
        "../images/cat11.svg",
    ];

    const more = (<iframe width="560" height="315" src="https://www.youtube.com/embed/0Wqm6CK4zMg" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>);

    return <AppContent title={title} src={src} language={language} description={description} images={images} more={more} key="cat"/>;
}

export default Cat;