import { IconContext } from 'react-icons'
import { SiJavascript, SiCss3, SiHtml5, SiPhp, SiWordpress, SiReact } from "react-icons/si";

import AppContent from "../AppContent";
import { MdTab } from 'react-icons/md';
import { GiPlug } from 'react-icons/gi';
import { BsFillPhoneFill } from 'react-icons/bs';

const UsefulTabBlock = () => {
    const title = "Useful Tab Block – Responsive & AMP-Compatible";
    const src = "../images/useful.svg";
    const language = (
        <>
            <ul className="no-point">
                <li>
                    <IconContext.Provider value={{ className: "fa-icon" }}>
                        <SiJavascript />
                    </IconContext.Provider>JavaScript
                </li>
                <li>
                    <IconContext.Provider value={{ className: "fa-icon" }}>
                        <SiReact />
                    </IconContext.Provider>React
                </li>
                <li>
                    <IconContext.Provider value={{ className: "fa-icon" }}>
                        <SiHtml5 />
                    </IconContext.Provider>HTML
                </li>
                <li>
                    <IconContext.Provider value={{ className: "fa-icon" }}>
                        <SiCss3 />
                    </IconContext.Provider>CSS
                </li>
                <li>
                    <IconContext.Provider value={{ className: "fa-icon" }}>
                        <SiPhp />
                    </IconContext.Provider>PHP
                </li>
                <li>
                    <IconContext.Provider value={{ className: "fa-icon" }}>
                        <SiWordpress />
                    </IconContext.Provider>WordPress
                </li>
            </ul>
            <h2>Keyword</h2>
            <ul className="no-point">
                <li>
                    <IconContext.Provider value={{ className: "fa-icon" }}>
                        <GiPlug />
                    </IconContext.Provider>WordPress Plugin
                </li>
                <li>
                    <IconContext.Provider value={{ className: "fa-icon" }}>
                        <MdTab />
                    </IconContext.Provider>WordPress Block
                </li>
                <li>
                    <IconContext.Provider value={{ className: "fa-icon" }}>
                        <BsFillPhoneFill />
                    </IconContext.Provider>Responsive Design
                </li>
            </ul>
        </>
    );
    const description = (
        <>
            <p><span className='bold'>Useful Tab Block – Responsive &amp; AMP-Compatible</span> is a WordPress plugin that adds responsive and AMP-compatible tab blocks to the Gutenberg editor.</p>
            <p>In the free version, the user can have at most 10 tabs and customize  
            </p>
            <ul>
                    <li>label background color (checked/unchecked)</li>
                    <li>label text color (checked/unchecked)</li>
                    <li>label shape (rect/round)</li>
                </ul>
            <p>The premium version provides four additional functionalities:
            </p>
            <ul>
                    <li>rearrangement of tabs</li>
                    <li>Fontawesome icons in tab labels</li>
                    <li>add/remove tabs more easily and intuitively</li>
                    <li>custom colors for label texts and backgrounds</li>
                </ul>
            <p>Both versions are available at the following links:</p>
            <ul>
                <li>Free version: <a href='https://wordpress.org/plugins/useful-tab-block-responsive-amp-compatible/' target="_blank" rel="noopener noreferrer">WordPress</a></li>
                <li>Premium version:
                    <ul>
                        <li><a href='https://beginner-blogger-com.stores.jp/items/620a293572c5de17cf64c521' target="_blank" rel="noopener noreferrer">Stores.jp</a></li>
                        <li><a href="https://bbc000tommy.gumroad.com/l/useful-tab-block-premium" target="_blank" rel="noopener noreferrer">Gumroad</a></li>
                    </ul>
                </li>
            </ul>
        </>
    );

    const images = [
        "../images/useful_tab_block.gif",
        "../images/useful_tab_block1.svg",
        "../images/useful_tab_block2.svg",
        "../images/useful_tab_block3.svg",
        "../images/useful_tab_block4.svg",
    ];
    const more = (
        <figure>
            <video src='../videos/useful_tab_block.mov' controls style={{ maxHeight: "400px", maxWidth: "90%" }}></video>
        </figure>
    );

    return <AppContent title={title} src={src} language={language} description={description} images={images} more={more} key="useful-tab-block"/>;
}

export default UsefulTabBlock;