import { Suspense } from 'react';
import { Link } from "react-router-dom";
import { LazyLoadImage, trackWindowScroll } from 'react-lazy-load-image-component';

import "../css/mobile.min.css";


const Application = (props, { scrollPosition }) => {
    return (
        <Link to={props.to} className="application">
            <article>
                <span className="language">{props.lang}</span>
                <Suspense fallback={<div>Image Loading...</div>}>
                    <figure>
                        <LazyLoadImage
                            src={props.src}
                            width="1200"
                            height="630"
                            scrollPosition={scrollPosition}
                        />
                    </figure>
                </Suspense>
                <div className="description">
                    <h3>{props.description}</h3>
                </div>
            </article>
        </Link>
    );
}

const Mobile = ({ scrollPosition, trackWindowScroll }) => {
    return (
        <div id="mobile">
            <h2 className="heading">Mobile Applications</h2>
            <div className="application-list">
                <Application
                    lang="Java"
                    src="/images/flickr.svg"
                    description="Image Fetch from Keyword &amp; GPS"
                    to="image-fetch"
                    scrollPosition={scrollPosition}
                    trackWindowScroll={trackWindowScroll}
                />
                <Application
                    lang="JavaScript"
                    src="/images/memory.svg"
                    description="Augmented Reality in Cemetries Based on Image Detection"
                    to="memory-react"
                    scrollPosition={scrollPosition}
                    trackWindowScroll={trackWindowScroll}
                />
                <Application
                    lang="Unity"
                    src="/images/memory_unity.svg"
                    description="Augmented Reality in Cemetries Based on Marker Recognition"
                    to="memory-unity"
                    scrollPosition={scrollPosition}
                    trackWindowScroll={trackWindowScroll}
                />
                <Application
                    lang="Unity"
                    src="/images/ar_navi.svg"
                    description="Campus Navigation Using Augmented Reality"
                    to="campus-navigation"
                    scrollPosition={scrollPosition}
                    trackWindowScroll={trackWindowScroll}
                />
                <Application
                    lang="Unity"
                    src="/images/louder.svg"
                    description="Louder Is Stronger"
                    to="louder-is-stronger"
                    scrollPosition={scrollPosition}
                    trackWindowScroll={trackWindowScroll}
                />
            </div>
        </div>
    );
}

export default trackWindowScroll(Mobile);