import { IconContext } from 'react-icons'
import { MdSmartphone } from "react-icons/md";
import { HiCubeTransparent } from "react-icons/hi";
import { SiUnity } from "react-icons/si";
import { MdGpsFixed } from "react-icons/md";

import AppContent from "../AppContent";

const CampusNavigation = () => {
    const title = "Campus Navigation Using Augmented Reality";
    const src = "../images/ar_navi.svg";
    const language = (
        <>
            <ul className="no-point">
                <li>
                    <IconContext.Provider value={{ className: "fa-icon" }}>
                        <SiUnity />
                    </IconContext.Provider>Unity
                </li>
                <li>
                    <IconContext.Provider value={{ className: "fa-icon" }}>
                        <SiUnity />
                    </IconContext.Provider>LAR Locative Augmented Reality
                </li>
            </ul>
            <h2>Keyword</h2>
            <ul className="no-point">
                <li>
                    <IconContext.Provider value={{ className: "fa-icon" }}>
                        <MdSmartphone />
                    </IconContext.Provider>Cross-Platform
                </li>
                <li>
                    <IconContext.Provider value={{ className: "fa-icon" }}>
                        <HiCubeTransparent />
                    </IconContext.Provider>Augmented Reality
                </li>
                <li>
                    <IconContext.Provider value={{ className: "fa-icon" }}>
                        <MdGpsFixed />
                    </IconContext.Provider>GPS
                </li>
            </ul>
        </>
    );
    const description = (
        <>
            <p>While there are a lot of map services available nowadays, there is no complete map. In private lands, for example, there is often no map. The campus of my school in France is one of them: it is so hard for new people such as fresh students to reach their destination.</p>
            <p>This application navigates users to their destination using <span className='bold'>GPS</span> and <span className='bold'>Augmented Reality</span>.</p>
            <p>GPS coordinates of destinations (e.g., the library, cafeteria, entrance, and dormitory) are pre-registered in the application. Once the user selects one of the destinations in the dropdown menu, the application indicates the direction to the destination by an arrow. The direction is computed from the current GPS coordinate and that of the destination.</p>
            <p>Sometimes people do not notice that they are already at the destination for some reason (e.g., there is no sign). The application displays AR 3D objects at the destination on the screen when they are near it to help the user be aware of that.</p>
            <p>As this application does not rely on maps, it can be used without maps, anywhere GPS is available.</p>
        </>
    );

    const images = [];
    const more = (
        <figure>
            <video src='../videos/ar_campus.mp4' controls style={{maxHeight: "400px", maxWidth: "90%"}}></video>
        </figure>
    );

    return <AppContent title={title} src={src} language={language} description={description} images={images} more={more} key="campus"/>;
}

export default CampusNavigation;