import { IconContext } from 'react-icons'
import { SiJavascript, SiCss3, SiHtml5, SiPhp, SiWordpress, SiJquery } from "react-icons/si";

import AppContent from "../AppContent";
import { BsFillPhoneFill } from 'react-icons/bs';
import { ImImage } from 'react-icons/im';
import { Link } from 'react-router-dom';

const HVRL = () => {
    const title = "WordPress Theme HVRL";
    const src = "../images/hvrl.svg";
    const language = (
        <>
            <ul className="no-point">
                <li>
                    <IconContext.Provider value={{ className: "fa-icon" }}>
                        <SiJavascript />
                    </IconContext.Provider>JavaScript
                </li>
                <li>
                    <IconContext.Provider value={{ className: "fa-icon" }}>
                        <SiJquery />
                    </IconContext.Provider>jQuery
                </li>
                <li>
                    <IconContext.Provider value={{ className: "fa-icon" }}>
                        <SiHtml5 />
                    </IconContext.Provider>HTML
                </li>
                <li>
                    <IconContext.Provider value={{ className: "fa-icon" }}>
                        <SiCss3 />
                    </IconContext.Provider>CSS
                </li>
                <li>
                    <IconContext.Provider value={{ className: "fa-icon" }}>
                        <SiPhp />
                    </IconContext.Provider>PHP
                </li>
                <li>
                    <IconContext.Provider value={{ className: "fa-icon" }}>
                        <SiWordpress />
                    </IconContext.Provider>WordPress
                </li>
            </ul>
            <h2>Keyword</h2>
            <ul className="no-point">
                <li>
                    <IconContext.Provider value={{ className: "fa-icon" }}>
                        <BsFillPhoneFill />
                    </IconContext.Provider>Responsive Design
                </li>
                <li>
                    <IconContext.Provider value={{ className: "fa-icon" }}>
                        <ImImage />
                    </IconContext.Provider>Graphic Design
                </li>
            </ul>
        </>
    );
    const description = (
        <>
            <p><span className='bold'>HVRL</span> is a custome WordPress theme used in <a href="http://www.hvrl.ics.keio.ac.jp" target="_blank" rel="noopener noreferrer">the website of the laboratory</a> I belong to. I developed it alone from scratch.</p>
            <p>It is responsive: it automatically adjusts the layout depending on the screen size. This is realized using CSS Grid Layout.
            </p>
            <p><Link to="../useful-tab-block">Useful Tab Block – Responsive &amp; AMP-Compatible</Link>, I developed, is incorporated into the theme and used in the front page. I also developed another Gutenberg block, which provides a custom button, and equipped it with the theme.</p>
            <p>The loading animation is what I designed. Drawing the image in a vector graphics editor, I exported it in the SVG format and added animation in CSS.</p>
        </>
    );

    const images = [
        "../images/hvrl2.png",
        "../images/hvrl3.png",
        "../images/hvrl4.png",
    ];
    const more = "";

    return <AppContent title={title} src={src} language={language} description={description} images={images} more={more} key="hvrl"/>;
}

export default HVRL;