import { Link } from "react-router-dom";
import { IconContext } from 'react-icons'
import { MdSmartphone } from "react-icons/md";
import { HiCubeTransparent } from "react-icons/hi";
import { SiJavascript, SiReact } from "react-icons/si";
import { RiBodyScanLine } from "react-icons/ri";

import AppContent from "../AppContent";

const ReactMemory = () => {
    const title = "Augmented Reality in Cemetries Based on Image Detection";
    const src = "../images/memory.svg";
    const language = (
        <>
            <ul className="no-point">
                <li>
                    <IconContext.Provider value={{ className: "fa-icon" }}>
                        <SiJavascript />
                    </IconContext.Provider>JavaScript
                </li>
                <li>
                    <IconContext.Provider value={{ className: "fa-icon" }}>
                        <SiReact />
                    </IconContext.Provider>React Native
                </li>
                <li>
                    <IconContext.Provider value={{ className: "fa-icon" }}>
                        <SiReact />
                    </IconContext.Provider>ViroReact
                </li>
            </ul>
            <h2>Keyword</h2>
            <ul className="no-point">
                <li>
                    <IconContext.Provider value={{ className: "fa-icon" }}>
                        <MdSmartphone />
                    </IconContext.Provider>Cross-Platform
                </li>
                <li>
                    <IconContext.Provider value={{ className: "fa-icon" }}>
                        <HiCubeTransparent />
                    </IconContext.Provider>Augmented Reality
                </li>
                <li>
                    <IconContext.Provider value={{ className: "fa-icon" }}>
                        <RiBodyScanLine />
                    </IconContext.Provider>Image Detection
                </li>
            </ul>
        </>
    );
    const description = (
        <>
            <p>When people have passed away, most of them are buried in graves. Some lie with their ancestors while others with other people in cemeteries. Not only is it, of course, difficult to imagine how other buried people were like and what they did and liked when they were alive, but the same is also true even for ancestors a couple of generations ago.</p>
            <p>While stone monuments can pass such information to future generations, they will decay and lose the information someday. Using <span className='bold'>Augmented Reality (AR)</span>, however, keeping and recalling important memories becomes much easier. Portraits, favorite music, companion animals, family videos, and a lot are permanent in AR. By scanning a gravestone of your loved ones, this application shows images, descriptions, and videos relative to the person.</p>
            <p>Technically speaking, the application is developed in <code>React Native</code>; it is hence available on both Android and iOS. AR experiences are developed using <code>ViroReact</code>, a framework of <code>React Native</code>, which provides a variety of APIs that makes it easier to create AR/VR features. The application tries to detect pre-registered images of gravestones in the image by the smartphone's camera. Once it detects one, it makes relevant data visible.</p>
            <p>This project is succeeded to the Unity version <Link to="../memory-unity">Augmented Reality in Cemetries Based on Marker Recognition</Link>.</p>
        </>
    );

    const images = [];
    const more = (
        <figure>
            <video src='../videos/memory_react.mov' controls style={{maxHeight: "400px", maxWidth: "90%"}}></video>
            <div>The demo starts from 2:22.</div>
        </figure>
    );

    return <AppContent title={title} src={src} language={language} description={description} images={images} more={more} key="react-memory"/>;
}

export default ReactMemory;