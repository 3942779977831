import ScrollToTopOnMount from '../ScrollToTopOnMount';
import Introduction from "./Introduction";
import Mobile from "./Mobile";
import Desktop from "./Desktop";
import Web from "./Web";
import Publication from "./Publication";
import "../css/style.min.css";

function Front() {
    return (
        <>
            <div id="content">
                <ScrollToTopOnMount />
                <Introduction />
                <Desktop />
                <Mobile />
                <Web />
                <Publication />
            </div>
        </>
    );
}

export default Front;