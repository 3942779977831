import { IconContext } from 'react-icons'
import { ImImage } from "react-icons/im";
import { SiJavascript, SiCss3, SiHtml5, SiJquery, SiPhp, SiWordpress } from "react-icons/si";

import AppContent from "../AppContent";
import { FaPenNib } from 'react-icons/fa';
import { MdAnimation } from 'react-icons/md';
import { BsFillPhoneFill } from 'react-icons/bs';

const Blog = () => {
    const title = "My Blog: beginner-blogger.com";
    const src = "../images/blog.svg";
    const language = (
        <>
            <ul className="no-point">
                <li>
                    <IconContext.Provider value={{ className: "fa-icon" }}>
                        <SiHtml5 />
                    </IconContext.Provider>HTML
                </li>
                <li>
                    <IconContext.Provider value={{ className: "fa-icon" }}>
                        <SiCss3 />
                    </IconContext.Provider>CSS
                </li>
                <li>
                    <IconContext.Provider value={{ className: "fa-icon" }}>
                        <SiJavascript />
                    </IconContext.Provider>JavaScript
                </li>
                <li>
                    <IconContext.Provider value={{ className: "fa-icon" }}>
                        <SiJquery />
                    </IconContext.Provider>jQuery
                </li>
                <li>
                    <IconContext.Provider value={{ className: "fa-icon" }}>
                        <SiPhp />
                    </IconContext.Provider>PHP
                </li>
                <li>
                    <IconContext.Provider value={{ className: "fa-icon" }}>
                        <SiWordpress />
                    </IconContext.Provider>WordPress
                </li>
            </ul>
            <h2>Keyword</h2>
            <ul className="no-point">
                <li>
                    <IconContext.Provider value={{ className: "fa-icon" }}>
                        <FaPenNib />
                    </IconContext.Provider>Blog/Writing
                </li>
                <li>
                    <IconContext.Provider value={{ className: "fa-icon" }}>
                        <BsFillPhoneFill />
                    </IconContext.Provider>Responsive Design
                </li>
                <li>
                    <IconContext.Provider value={{ className: "fa-icon" }}>
                        <ImImage />
                    </IconContext.Provider>Graphic Design
                </li>
                <li>
                    <IconContext.Provider value={{ className: "fa-icon" }}>
                        <MdAnimation />
                    </IconContext.Provider>Animation
                </li>
            </ul>
        </>
    );
    const description = (
        <>
            <p>I have been running a blog <span className='bold'>beginner-blogger.com</span> since 2020.</p>
            <p>The blog provides information on
            </p>
            <ul>
                <li>language learning (English/French)</li>
                <li>studying in France</li>
                <li>WordPress settings/customization</li>
            </ul>
            <p>While the design is based on a free WordPress theme, I am customizing most parts using CSS, JavaScript, and PHP. </p>
            <p>I like to design graphics: most of the thumbnails and images used in the blog are what I created.</p>
            <p>My blog is hosted on <a href='https://beginner-blogger.com' target="_blank" rel="noopener noreferrer">beginner-blogger.com</a>.</p>
        </>
    );

    const images = [
        "../images/blog1.svg",
        "../images/blog2.svg",
        "../images/blog3.svg",
    ];
    const more = "";

    return <AppContent title={title} src={src} language={language} description={description} images={images} more={more} key={"blog"}/>;
}

export default Blog;