import React from 'react'
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { useLoader } from "@react-three/fiber";


const Model = (props) => {
	const obj = useLoader(OBJLoader, "/images/lab_room.obj");
	return <primitive object={obj}/>;
}


export default Model;