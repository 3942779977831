import { Suspense } from 'react';
import { Link } from "react-router-dom";
import { LazyLoadImage, trackWindowScroll } from 'react-lazy-load-image-component';
import "../css/web.min.css";

const Application = (props, { scrollPosition }) => {
    return (
        <Link to={props.to} className="application">
            <article>
                <span className="language">{props.lang}</span>
                <Suspense fallback={<div>Image Loading...</div>}>
                    <figure>
                        <LazyLoadImage
                            src={props.src}
                            width="1200"
                            height="630"
                            scrollPosition={scrollPosition}
                        />
                    </figure>
                </Suspense>
                <div className="description">
                    <h3>{props.description}</h3>
                </div>
            </article>
        </Link>
    );
}

const Web = ({ scrollPosition, trackWindowScroll }) => {
    return (
        <div id="web">
            <h2 className="heading">Web Applications</h2>
            <div className="application-list">
                <Application
                    lang="JavaScript"
                    src="/images/blog.svg"
                    description="My Blog"
                    to="blog"
                    scrollPosition={scrollPosition}
                    trackWindowScroll={trackWindowScroll}
                />
                <Application
                    lang="JavaScript"
                    src="/images/useful.svg"
                    description="Useful Tab Block – Responsive &amp; AMP-Compatible"
                    to="useful-tab-block"
                    scrollPosition={scrollPosition}
                    trackWindowScroll={trackWindowScroll}
                />
                <Application
                    lang="PHP"
                    src="/images/hvrl.svg"
                    description="WordPress Theme HVRL"
                    to="hvrl"
                    scrollPosition={scrollPosition}
                    trackWindowScroll={trackWindowScroll} />
            </div>
        </div>
    );
}

export default trackWindowScroll(Web);