import { IconContext } from 'react-icons'
import { MdSmartphone } from "react-icons/md";
import { SiUnity } from "react-icons/si";
import { BsFillMegaphoneFill } from "react-icons/bs";

import AppContent from "../AppContent";

const Louder = () => {
    const title = "Louder Is Stronger";
    const src = "../images/louder.svg";
    const language = (
        <>
            <ul className="no-point">
                <li>
                    <IconContext.Provider value={{ className: "fa-icon" }}>
                        <SiUnity />
                    </IconContext.Provider>Unity
                </li>
            </ul>
            <h2>Keyword</h2>
            <ul className="no-point">
                <li>
                    <IconContext.Provider value={{ className: "fa-icon" }}>
                        <MdSmartphone />
                    </IconContext.Provider>Cross-Platform
                </li>
                <li>
                    <IconContext.Provider value={{ className: "fa-icon" }}>
                        <BsFillMegaphoneFill />
                    </IconContext.Provider>Voice User Interaction (VUI)
                </li>
            </ul>
        </>
    );
    const description = (
        <>
            <p><span className='bold'>Louder Is Stronger</span> is a game application in which the player fights against enemies using voice. The louder and the longer the player shouts, the more damage they can deal.</p>
            <p>It provides two stages:
            </p>
            <ul>
                <li>Level Up Stage</li>
                <li>Time Attack Stage</li>
            </ul>
            <p>In <span className='bold'>Level Up Stage</span>, the player fights against an enemy in 30 seconds. If the player successfully defeats it (i.e., reduce the enemy's HP to 0) in 30 seconds, they can move to the next level. The goal is to advance to the highest level as possible.</p>
            <p>In <span className='bold'>Time Attack Stage</span>, the player fights against an enemy in 60 seconds. This time, however, the enemy is immortal, and there is no "level." Instead, the goal is to deal as much damage as possible in 60 seconds.</p>
            <p>The player can select the character, and the best scores on both stages are saved.</p>
            <p><span className='bold'>Louder is Stronger</span> is available on <a href='https://play.google.com/store/apps/details?id=com.Team10.LouderisStronger' target="_blank" rel="noopener noreferrer">Google Play Store</a>.</p>
        </>
    );

    const images = [];
    const more = (
        <figure>
            <video src='../videos/louder.mp4' controls style={{ maxHeight: "90vh", maxWidth: "90%" }}></video>
        </figure>
    );

    return <AppContent title={title} src={src} language={language} description={description} images={images} more={more} key="louder"/>;
}

export default Louder;