import logo from "./mylogo.svg";
import { Outlet, Link } from "react-router-dom";
import './css/header.min.css';

function Header() {
    return (
        <>
            <header>
                <Link to="/" id='header-name-container'>
                    <figure>
                        <img width="70" height="70" src={logo} className="header-logo" alt="logo" />
                    </figure>
                    <div className="name">Tomoya Matsubara's Portfolio</div>
                </Link>
            </header>
            <Outlet />
        </>
    );
}

export default Header;