import { IconContext } from 'react-icons'
import { FaRust, FaServer } from "react-icons/fa";
import { GrMultiple } from "react-icons/gr";

import AppContent from "../AppContent";

const Snake = () => {
    const title = "Multi-Player Snake Game";
    const src = "../images/snake.svg";
    const language = (
        <>
        <ul className="no-point">
            <li>
                <IconContext.Provider value={{ className: "fa-icon" }}>
                    <FaRust />
                </IconContext.Provider>Rust
            </li>
        </ul>
        <h2>Keyword</h2>
        <ul className="no-point">
            <li>
                <IconContext.Provider value={{ className: "fa-icon invert" }}>
                    <GrMultiple />
                </IconContext.Provider>Multi-Threading
            </li>
            <li>
                <IconContext.Provider value={{ className: "fa-icon" }}>
                    <FaServer />
                </IconContext.Provider>Server-Client Communication
            </li>
        </ul>
        </>
    );
    const description = (
        <>
            <p><span className='bold'>Snake</span> is a video game in which the player controls a snake that moves at a constant speed in a bordered area. The snake can move up, down, right or left, and grows if it picks up food, which is randomly placed. The game is over when it hits the boundary. The goal is to grow as many times as possible, i.e., pick up as much food as possible.</p>
            <p>I extended this game to the multi-player version: each player controls their snake and tries to pick up food. The only difference from the single-player version is that if a snake hits another snake, they both die, and their players can no longer play the game.</p>
            <p>The game is hosted on a server, and players connect to it to play the game. While the rendering of the screen and the handling of the player's input are done on the client-side, everything else (e.g., hit checks) is done on the server-side. To avoid the server being too occupied, the server assignes a thread to each player.</p>
        </>
    );

    const images = [
        "../images/snake1.png",
        "../images/snake1.svg",
        "../images/snake2.svg",
    ];
    const more = (
        <figure>
            <video src='../videos/snake1.mp4' controls style={{maxHeight: "400px", maxWidth: "90%"}}></video>
        </figure>
    );

    return <AppContent title={title} src={src} language={language} description={description} images={images} more={more} key="snake"/>;
}

export default Snake;