import { LazyLoadImage, trackWindowScroll } from 'react-lazy-load-image-component';
import ScrollToTopOnMount from './ScrollToTopOnMount';
import "./css/app_page.min.css";

const AppContent = (props, { scrollPosition, trackWindowScroll }) => {

    const images = props.images.length !== 0 ?
        <div className='image-wrapper'>
            <div className='images-pdfs'>
                {props.images.map((image_src, index) =>
                    <figure key={index}>
                        <LazyLoadImage
                            width="70"
                            height="70"
                            scrollPosition={scrollPosition}
                            src={image_src}
                        />
                    </figure>
                )}
            </div>
            {props.more !== "" ?
                <div className="more">{props.more}</div> : null}
        </div>
        : props.more !== "" ?
            <div className='image-wrapper'>
                <div className="more">{props.more}</div>
            </div>
            : "";

    return (
        <>
            <ScrollToTopOnMount />
            <div id="content" className="app-page">
                <h1>{props.title}</h1>
                <figure>
                    <LazyLoadImage
                        width="70"
                        height="70"
                        scrollPosition={scrollPosition}
                        src={props.src}
                    />
                </figure>
                <div className="description-wrapper">
                    <div className="description">
                        <div className="detail">{props.description}</div>
                        <div className="info">
                            <h2>Language/Framework</h2>
                            {props.language}
                        </div>
                    </div>
                </div>
                {images}
            </div>
        </>
    );
};

export default trackWindowScroll(AppContent);