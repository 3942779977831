import { IconContext } from 'react-icons'
import { FaPython, FaGamepad } from "react-icons/fa";
import { GiFamilyTree, GiTicTacToe } from "react-icons/gi";
import { RiNodeTree } from "react-icons/ri";

import AppContent from "../AppContent";

const GameAI = () => {
    const title = "Game Theory Based Game AI";
    const src = "../images/game_ai.svg";
    const language = (
        <>
            <ul className="no-point">
                <li>
                    <IconContext.Provider value={{ className: "fa-icon" }}>
                        <FaPython />
                    </IconContext.Provider>Python
                </li>
                <li>
                    <IconContext.Provider value={{ className: "fa-icon" }}>
                        <FaPython />
                    </IconContext.Provider>Tkinter
                </li>
            </ul>
            <h2>Keyword</h2>
            <ul className="no-point">
                <li>
                    <IconContext.Provider value={{ className: "fa-icon" }}>
                        <FaGamepad />
                    </IconContext.Provider>Game Theory
                </li>
                <li>
                    <IconContext.Provider value={{ className: "fa-icon rotate" }}>
                        <GiFamilyTree />
                    </IconContext.Provider>Monte Carlo Tree Search (MCTS)
                </li>
                <li>
                    <IconContext.Provider value={{ className: "fa-icon " }}>
                        <RiNodeTree />
                    </IconContext.Provider>Alpha-Beta Pruning
                </li>
                <li>
                    <IconContext.Provider value={{ className: "fa-icon" }}>
                        <GiTicTacToe />
                    </IconContext.Provider>GUI
                </li>
            </ul>
        </>
    );
    const description = (
        <>
            <p>This is an application of board games equipped with my Artificial Intelligence (AI).</p>
            <p>I developed both the command line version and the GUI version of
            </p>
            <ul className="bold">
                <li>Tic-Tac-Toe</li>
                <li>Ultimate Tic-Tac-Toe</li>
                <li>Checkers</li>
            </ul>
            <p>from scratch. For the GUI develoment, I used <code>Tkinter</code> in Python.</p>
            <p>As for AI, I implemented <span className='bold'>Alpha-Beta Pruning</span> in Tic-Tac-Toe. Since Tic-Tac-Toe is relatively simple, its game tree can exhaustively be searched. Therefore, my AI is the strongest in theory: it can always select the best strategy.</p>
            <p>In contrast, checkers is much more complex than Tic-Tac-Toe, which makes Alpha-Beta Pruning less useful. I thus implemented the <span className='bold'>Upper Confidence bounds applied to Trees (UCT)</span> algorithm instead. UCT is a variant of <span className='bold'>Monte Carlo Tree Search (MCTS)</span>. The algorithm achieved a winning rate of about 60% against the strongest AI of a checker application.</p>
        </>
    );

    const images = [
        "../images/game_ai1.png",
        "../images/game_ai2.png",
        "../images/game_ai3.png",
        "../images/game_ai4.png"
    ];

    const more = "";

    return <AppContent title={title} src={src} language={language} description={description} images={images} more={more} key="gameai"/>;
}

export default GameAI;