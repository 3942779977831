import { IconContext } from 'react-icons'
import { FaPenNib } from "react-icons/fa";
import "../css/publication.min.css";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Publication = () => {
    return (
        <div id="publication">
            <h2 className="heading">Publications</h2>
            <ul className="paper-list">
                <li>
                    <IconContext.Provider value={{ className: "paper-icon" }}>
                        <FaPenNib />
                    </IconContext.Provider>
                    Shared Transformer Encoder With Mask-Based 3D Model Estimation For Container Mass Estimation
                    <ul>
                        <li>Tomoya Matsubara, Seitaro Otsuki, Yuiga Wada, Haruka Matsuo, Takumi Komatsu, Yui Iioka, Komei Sugiura, Hideo Saito</li>
                        <li><i>ICASSP 2022-2022 IEEE International Conference on Acoustics, Speech and Signal Processing (ICASSP)</i>. IEEE, 2022.</li>
                    </ul>
                    <div className="publication-images">
                        <figure>
                            <LazyLoadImage
                                width="70"
                                height="70"
                                src={"../images/poster.svg"}
                            />
                        </figure>
                        <figure>
                            <LazyLoadImage
                                width="70"
                                height="70"
                                src={"../images/CORSMAL-Challenge_Workshop__Certificate.svg"}
                            />
                        </figure>
                    </div>
                </li>
            </ul>
        </div>
    );
}

export default Publication;