import React, { Suspense } from 'react'
import { Canvas } from "@react-three/fiber";
import { OrbitControls, PerspectiveCamera, Stage } from "@react-three/drei";

import Model from './Model';

const DrawCanvas = () => {

	const fallback = <div className='model-loading'>Model Loading...</div>;


	return (
		<Suspense fallback={fallback}>
			<Canvas>
				<PerspectiveCamera makeDefault zoom={2} />
				<OrbitControls />
				<Stage>
					<Model />
				</Stage>
			</Canvas>
		</Suspense>
	);
}

export default DrawCanvas;