import { Suspense } from 'react';
import { Link } from "react-router-dom";
import { LazyLoadImage, trackWindowScroll } from 'react-lazy-load-image-component';
import "../css/desktop.min.css";

const Application = (props, { scrollPosition }) => {
    return (
        <Link to={props.to} className="application">
            <article>
                <span className="language">{props.lang}</span>
                <Suspense fallback={<div>Image Loading...</div>}>
                    <figure>
                        <LazyLoadImage
                            width="1200"
                            height="630"
                            src={props.src}
                            scrollPosition={scrollPosition}
                        />
                    </figure>
                </Suspense>
                <div className="description">
                    <h3>{props.description}</h3>
                </div>
            </article>
        </Link>
    );
}

const Desktop = ({ scrollPosition, trackWindowScroll }) => {
    return (
        <div id="desktop">
            <h2 className="heading">Desktop Applications</h2>
            <div className="application-list">
                <Application
                    lang="Python"
                    src="/images/vrp.svg"
                    description="Vehicle Routing Problem (VRP) Solver"
                    to="vrp"
                    scrollPosition={scrollPosition}
                    trackWindowScroll={trackWindowScroll}
                />
                <Application
                    lang="Python"
                    src="/images/cat.svg"
                    description="Cat Detector with a Haar Cascade Classifier"
                    to="cat-detector"
                    scrollPosition={scrollPosition}
                    trackWindowScroll={trackWindowScroll}
                />
                <Application
                    lang="Python"
                    src="/images/game_ai.svg"
                    description="Game Theory Based Game AI"
                    to="game-ai"
                    scrollPosition={scrollPosition}
                    trackWindowScroll={trackWindowScroll}
                />
                <Application
                    lang="Rust"
                    src="/images/snake.svg"
                    description="Multi-Player Snake Game"
                    to="snake"
                    scrollPosition={scrollPosition}
                    trackWindowScroll={trackWindowScroll}
                />
                <Application
                    lang="Java"
                    src="/images/library.svg"
                    description="Library Database Management Tool"
                    to="library"
                    scrollPosition={scrollPosition}
                    trackWindowScroll={trackWindowScroll}
                />
            </div>
        </div>
    );
}

export default trackWindowScroll(Desktop);