import { useState } from 'react';

const useToken = () => {

    const [token, setToken] = useState(false);

    const saveToken = userToken => {
        setToken(userToken);
    };

    return {
        setToken: saveToken,
        token
    }
}

export default useToken;