import { IconContext } from 'react-icons'
import { FaJava, FaServer, FaProjectDiagram } from "react-icons/fa";
import { GiTicTacToe } from "react-icons/gi";
import { SiSqlite } from "react-icons/si";

import AppContent from "../AppContent";

const Library = () => {
    const title = "Library Database Management Tool";
    const src = "../images/library.svg";
    const language = (
        <>
            <ul className="no-point">
                <li>
                    <IconContext.Provider value={{ className: "fa-icon" }}>
                        <FaJava />
                    </IconContext.Provider>Java
                </li>
                <li>
                    <IconContext.Provider value={{ className: "fa-icon" }}>
                        <FaJava />
                    </IconContext.Provider>Swing
                </li>
                <li>
                    <IconContext.Provider value={{ className: "fa-icon" }}>
                        <SiSqlite />
                    </IconContext.Provider>SQLite
                </li>
                <li>
                    <IconContext.Provider value={{ className: "fa-icon" }}>
                        <FaProjectDiagram />
                    </IconContext.Provider>UML
                </li>
            </ul>
            <h2>Keyword</h2>
            <ul className="no-point">
                <li>
                    <IconContext.Provider value={{ className: "fa-icon" }}>
                        <GiTicTacToe />
                    </IconContext.Provider>GUI
                </li>
                <li>
                    <IconContext.Provider value={{ className: "fa-icon" }}>
                        <FaServer />
                    </IconContext.Provider>Server
                </li>
            </ul>
        </>
    );
    const description = (
        <>
            <p>This application helps library users (i.e., normal users and admin users) manipluate information stored in the SQLite database.</p>
            <p>Normal users can
            </p>
            <ul>
                <li>borrow, return, and search for books,</li>
                <li>check what they are borrowing,</li>
                <li>check and change registered personal information.</li>
            </ul>
            <p>In addition to them, admin users can
            </p>
            <ul>
                <li>add and remove books,</li>
                <li>add and remove users,</li>
                <li>stop certain users from borrowing,</li>
                <li>create a book categories.</li>
            </ul>
            <p>GUI of the application is designed using <code>Java Swing</code>.</p>
            <p>The design of the application started from modeling in <code>UML</code>.</p>
        </>
    );

    const images = [
        "../images/library1.png",
        "../images/library2.png",
        "../images/library3.png",
        "../images/library4.png",
        "../images/library5.png",
        "../images/library6.png",
    ];
    const more = "";

    return <AppContent title={title} src={src} language={language} description={description} images={images} more={more} key="library"/>;
}

export default Library;