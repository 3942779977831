import { IconContext } from 'react-icons'
import { FaJava, FaFlickr } from "react-icons/fa";
import { MdGpsFixed, MdHttp} from "react-icons/md";
import { GrAndroid } from "react-icons/gr";
import { BsFillFileLock2Fill } from "react-icons/bs";

import AppContent from "../AppContent";

const ImageFetch = () => {
    const title = "Image Fetch from Keyword & GPS";
    const src = "../images/flickr.svg";
    const language = (
        <>
            <ul className="no-point">
                <li>
                    <IconContext.Provider value={{ className: "fa-icon" }}>
                        <FaJava />
                    </IconContext.Provider>Java
                </li>
                <li>
                    <IconContext.Provider value={{ className: "fa-icon" }}>
                        <FaFlickr />
                    </IconContext.Provider>Flickr
                </li>
            </ul>
            <h2>Keyword</h2>
            <ul className="no-point">
                <li>
                    <IconContext.Provider value={{ className: "fa-icon" }}>
                        <GrAndroid />
                    </IconContext.Provider>Android
                </li>
                <li>
                    <IconContext.Provider value={{ className: "fa-icon" }}>
                        <BsFillFileLock2Fill />
                    </IconContext.Provider>Basic Authentication
                </li>
                <li>
                    <IconContext.Provider value={{ className: "fa-icon" }}>
                        <MdGpsFixed />
                    </IconContext.Provider>GPS
                </li>
                <li>
                    <IconContext.Provider value={{ className: "fa-icon" }}>
                        <MdHttp />
                    </IconContext.Provider>REST API
                </li>
            </ul>
        </>
    );
    const description = (
        <>
            <p>This Android application fetches images from <span className='bold'>Flickr</span> corresponding to the user's input Keyword. </p>
            <p>The first screen users see is the login screen. As <span className="bold">basic authentication</span> is implemented, users need to input the correct username-password pair to go into the main screen.
            </p>
            <p>Flickr API returns JSON data and the application extracts URLs of images and downloads them. Downloading data is executed asynchronously not to block the main thread. To reduce unneccesary HTTP requests, users can enable image caching.
            </p>
            <p>The application also provides the functionality to fetch images based on the user's current <span className='bold'>GPS</span> coordinate.</p>
        </>
    );

    const images = [
        "../images/flickr1.jpg",
        "../images/flickr2.jpg",
        "../images/flickr3.jpg",
        "../images/flickr4.jpg",
        "../images/flickr5.jpg",
    ];
    const more = "";

    return <AppContent title={title} src={src} language={language} description={description} images={images} more={more} key="imagefetch"/>;
}

export default ImageFetch;