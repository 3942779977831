import { IconContext } from 'react-icons'
import { FaPython } from "react-icons/fa";
import { GiDna1 } from "react-icons/gi";

import AppContent from "../AppContent";

const VRP = () => {
    const title = "Vehicle Routing Problem (VRP) Solver";
    const src = "../images/vrp.svg";
    const language = (
        <>
        <ul className="no-point">
            <li>
                <IconContext.Provider value={{ className: "fa-icon" }}>
                    <FaPython />
                </IconContext.Provider>Python
            </li>
        </ul>
        <h2>Keyword</h2>
        <ul className="no-point">
            <li>
                <IconContext.Provider value={{ className: "fa-icon" }}>
                    <GiDna1 />
                </IconContext.Provider>Genetic Algorithm
            </li>
        </ul>
        </>
    );
    const description = (
        <>
            <p><span className='bold'>Vehicle Routing Problem (VRP) Solver</span> is a command line application to solve a VRP, which generalizes the Travelling Salesman Problem (TSP).</p>
            <p>The problem asks for determining, under certain constraints, a set of routes to deliver goods to all customers. In this application, I suppose each vehicle can deliver a limited number of goods and the application gives the route that minimizes the total distance.</p>
            <p>The application is based on a Genetic Algorithm (GA): each gene is represented by an array whose length is equal to the number of routes, and each permutation of the numbers represents the order of routes to take. After generating the first population in a random manner, it populates new generations by partially matched crossover and inversion and swap mutation to seek for better solutions.</p>
        </>
    );
    const images = [
        "../images/vrp1.svg",
        "../images/vrp2.svg",
        "../images/vrp3.svg",
        "../images/vrp4.svg",
        "../images/vrp5.svg",
        "../images/vrp6.svg",
        "../images/vrp7.svg",
        "../images/vrp8.svg",
    ];

    const more = (<iframe width="560" height="315" src="https://www.youtube.com/embed/0Wqm6CK4zMg" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>);

    return <AppContent title={title} src={src} language={language} description={description} images={images} more={more} key="vrp"/>;
}

export default VRP;