import DrawCanvas from "./DrawCanvas";
import "../css/Introduction.min.css";

function Introduction() {
    return (
        <div id="intro-wrapper">
            <div id="introduction">
                <div className="brief-introduction">
                    <h1>Tomoya Matsubara</h1>
                    <h2>First-Year Master Student at Keio University</h2>
                    <p>Majoring in computer science, I have been developing desktop and mobile applications. My current research is about change detection in indoor point clouds.</p>
                </div>
                <div id="model">
                    <DrawCanvas />
                </div>
            </div>
        </div>
    );
}

export default Introduction;
