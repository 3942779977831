import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./Header";
import Front from "./Front/Front";
import VRP from "./AppPage/VRP";
import Cat from "./AppPage/Cat";
import GameAI from "./AppPage/GameAI";
import Snake from "./AppPage/Snake";
import Library from "./AppPage/Library";
import ImageFetch from "./AppPage/ImageFetch";
import ReactMemory from "./AppPage/ReactMemory.js";
import UnityMemory from "./AppPage/UnityMemory";
import CampusNavigation from "./AppPage/CampusNavigation";
import Louder from "./AppPage/Louder";
import Blog from "./AppPage/Blog";
import UsefulTabBlock from "./AppPage/UsefulTabBlock";
import HVRL from "./AppPage/HVRL";
import Login from "./Login";
import useToken from "./useToken";


function App() {
  const { token, setToken } = useToken();

  if (!token) {
    return <Login token={token} setToken={setToken} />
  }

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Header />}>
            <Route index element={<Front />} />
            <Route path="vrp" element={<VRP />} />
            <Route path="cat-detector" element={<Cat />} />
            <Route path="game-ai" element={<GameAI />} />
            <Route path="snake" element={<Snake />} />
            <Route path="library" element={<Library />} />
            <Route path="image-fetch" element={<ImageFetch />} />
            <Route path="memory-react" element={<ReactMemory />} />
            <Route path="memory-unity" element={<UnityMemory />} />
            <Route path="campus-navigation" element={<CampusNavigation />} />
            <Route path="louder-is-stronger" element={<Louder />} />
            <Route path="blog" element={<Blog />} />
            <Route path="useful-tab-block" element={<UsefulTabBlock />} />
            <Route path="hvrl" element={<HVRL />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;