import { Link } from "react-router-dom";
import { IconContext } from 'react-icons'
import { MdSmartphone } from "react-icons/md";
import { HiCubeTransparent } from "react-icons/hi";
import { SiJavascript, SiReact, SiUnity } from "react-icons/si";
import { RiBodyScanLine } from "react-icons/ri";

import AppContent from "../AppContent";

const UnityMemory = () => {
    const title = "Augmented Reality in Cemetries Based on Marker Recognition";
    const src = "../images/memory_unity.svg";
    const language = (
        <>
            <ul className="no-point">
                <li>
                    <IconContext.Provider value={{ className: "fa-icon" }}>
                        <SiUnity />
                    </IconContext.Provider>Unity
                </li>
                <li>
                    <IconContext.Provider value={{ className: "fa-icon" }}>
                        <SiUnity />
                    </IconContext.Provider>Vuforia
                </li>
                <li>
                    <IconContext.Provider value={{ className: "fa-icon" }}>
                        <SiJavascript />
                    </IconContext.Provider>JavaScript
                </li>
                <li>
                    <IconContext.Provider value={{ className: "fa-icon" }}>
                        <SiReact />
                    </IconContext.Provider>React Native
                </li>
            </ul>
            <h2>Keyword</h2>
            <ul className="no-point">
                <li>
                    <IconContext.Provider value={{ className: "fa-icon" }}>
                        <MdSmartphone />
                    </IconContext.Provider>Cross-Platform
                </li>
                <li>
                    <IconContext.Provider value={{ className: "fa-icon" }}>
                        <HiCubeTransparent />
                    </IconContext.Provider>Augmented Reality
                </li>
                <li>
                    <IconContext.Provider value={{ className: "fa-icon" }}>
                        <RiBodyScanLine />
                    </IconContext.Provider>Image Recognition
                </li>
            </ul>
        </>
    );
    const description = (
        <>
            <p>This project suceeds to the <code>React Native</code> version <Link to="../memory-react">Augmented Reality in Cemetries Based on Image Detection</Link>. While the previous version was developed by myself, this project is organized with four fellow students.</p>
            <p>The previous version was not good at detecting images when external environments (e.g., weather, brightness, and hours) are different from those of the pre-registered image. For example, when it was dark in the evening, the application was unlikely to detect gravestones whose pre-registered images were taken in the morning. This is because pre-registered images contain not only a gravestone but also the background.</p>
            <p>To make the detection process more robust, our team developed custom AR markers and used them as targets of detection. We accordingly changed the AR framework from <code>ViroReact</code> to <code>Vuforia</code> because <code>ViroReact</code> had not been updated for a while and produced a lot of bugs. Since we found it easier to develop front-end in <code>React Native</code> in our case, we developed UIs in <code>React Native</code> and AR experiences in <code>Unity</code> using <code>Vuforia</code>.</p>
        </>
    );

    const images = [];
    const more = (
        <figure>
            <video src='../videos/memory_unity.mp4' controls style={{maxHeight: "90vh", maxWidth: "90%"}}></video>
        </figure>
    );

    return <AppContent title={title} src={src} language={language} description={description} images={images} more={more} key="unity-memory"/>;
}

export default UnityMemory;