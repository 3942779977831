import { useState } from 'react';
import PropTypes from 'prop-types';
import "./css/login.min.css";


const Login = ( {token, setToken} ) => {
    const [username, setUserName] = useState();
    const [password, setPassword] = useState();
    const [message, setMessage] = useState("");

    const handleSubmit = async e => {
        e.preventDefault();

        if (username === "tomoya matsubara" && password === "vP6dmg5B") {
            setToken(true);
        } else {
            setMessage("Username of Password is incorrect");
        }
    }


    return (
        <div className="login-wrapper">
            <form onSubmit={handleSubmit}>
                <label>
                    <p>Username</p>
                    <input type="text" onChange={e => setUserName(e.target.value)}/>
                </label>
                <label>
                    <p>Password</p>
                    <input type="password" onChange={e => setPassword(e.target.value)}/>
                </label>
                <div className="submit-button">
                    <button type="submit">Login</button>
                </div>
                {
                !token ? 
                    <div className='message'>{message}</div> 
                    : <></>
                }
            </form>
            
        </div>
    );
}

Login.propTypes = {
    setToken: PropTypes.func.isRequired
}

export default Login;